import React from "react";
import { Link } from "react-router-dom";

export const Frames = ({ frames, loading }) => {
  let valorApoio = 0;
  let valorProjeto = 0;
  if (loading) {
    return (
      <ul className="item justify-content-center">
        <h3 style={{ marginTop: "300px", height: "100%" }}>Loading...</h3>
      </ul>
    );
  }

  let toEUR = new Intl.NumberFormat("pt-PT", {
    style: "currency",
    currency: "EUR",
  });

  return (
    <div>
      {frames.map((post) => {
        valorApoio = toEUR.format(post.valor_apoio);
        valorProjeto = toEUR.format(post.valor_projeto);

        return (
          <div className="container-fluid" key={post.id_projeto}>
            <div className="row">
              <div className="col-12">
                <div className="cardPubs" style={{ display: "flex" }}>
                  <div className="col-xl-9 col-lg-9">
                    <div className="cardPubs-body">
                      <div className="cardPubs-title">
                        <Link
                          to={{
                            pathname: "/services-details/" + post.id_projeto,
                            state: { projectID: post.id_projeto },
                          }}
                        >
                          <span style={{ fontSize: "20px" }}>
                            <b>{post.ds_projeto}</b>
                          </span>
                        </Link>
                      </div>
                      <div className="cardPubs-text">
                        {post.resumo === "" ? (
                          <div></div>
                        ) : (
                          <div>
                            <br></br>
                            <span>{post.resumo}</span>
                            <br></br>
                            <br></br>
                          </div>
                        )}
                        <label style={{ fontSize: "15px", color: "#53565At" }}>
                          <b>Start Date:</b>
                        </label>
                        <span> {post.data_ini}</span>
                        <label
                          style={{
                            fontSize: "15px",
                            color: "#53565At",
                            marginLeft: "10px",
                          }}
                        >
                          <b>End Date:</b>
                        </label>
                        <span> {post.data_prevista_fim}</span>
                        <br></br>
                        <label style={{ fontSize: "15px", color: "#53565At" }}>
                          <b>IPVC Budget:</b>
                        </label>
                        <span> {valorApoio}</span>
                        <label
                          style={{
                            fontSize: "15px",
                            color: "#53565At",
                            marginLeft: "10px",
                          }}
                        >
                          <b>Total Budget:</b>
                        </label>
                        <span> {valorProjeto}</span>
                        <a href={post.url}></a>
                        <br></br>
                        <br></br>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Frames;
