import React from "react";

let sauce;

export const Posts = ({ posts, loading }) => {
  if (loading) {
    return (
      <ul className="item">
        <h3 style={{ alignContent: "center", marginTop: "500px" }}>
          Loading...
        </h3>
      </ul>
    );
  }

  return (
    <div>
      {posts.map((post) => {
        let afiliacaoSegments = post.AFILIACAO.split("#");
        if (post.PATH_IMAGE === null || post.PATH_IMAGE === '') {
          sauce = "https://adit.ipvc.pt/backend/backend/fotos/adit/test.svg"
        } else {
          sauce = "https://adit.ipvc.pt/modulosipvc_producao/frontend/images/comites/" + post.PATH_IMAGE
        }
        return (
          <div className="row">
            <div className="ESAC-Space col-lg-12">
              <div className="esac-item">
                <div className="row" style={{ alignItems: 'center' }}>
                  <div className="col-xl-3 col-lg-12" style={{ display: 'flex', textAlign: 'center' }}>
                    <div className="esac-image">
                      <img
                        src={sauce}
                        className="img-fluid"
                        alt="ESAC"

                      />
                    </div>
                  </div>
                  <div className="col-xl-9 col-lg-12">
                    <div className="esac-title">
                      <span>
                        <b>{post.NOME}</b>
                      </span>
                    </div>
                    <div className="esac-body">
                      {afiliacaoSegments.map((segment, index) => (
                        <p key={index}>{segment}</p>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>


          </div>
        );
      })}
    </div>
  );
};

export default Posts;
