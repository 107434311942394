import React, { useState, useEffect } from "react";
import axios from "axios";
import Posts from "../../data/PostsGovernance.js";
import PostsUgp from "../../data/PostsUgp.js";
import _, { } from "lodash";
import Button from 'react-bootstrap/Button'
import TeamEsac from "./TeamEsac.jsx";
import Stakeholders from "./Stakeholders.jsx";
//import { config } from '../../data/config.js';


const Governance = () => {


    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [cargos, setCargos] = useState([]);
    const [allPosts, setAllPosts] = useState([]);
    const [ugp, setUgp] = useState([]);
    const [isEsac, setEsac] = useState(false);
    const [isCC, setCC] = useState(false);
    const [isDirection, setDirection] = useState(true);
    const [isUgp, setIsUgp] = useState(false);
    const [ui, setUi] = useState([]);
    const [selectedCargo, setSelectedCargo] = useState('Direction');

    useEffect(() => {

        const fetchPosts = async () => {
            setLoading(true);
            const res = await axios.get(window.config.DEV_LINK + "/investigador/getByUi/" + window.config.id);
            setAllPosts(res.data.payload)

            const ugpRequest = await axios.get(window.config.DEV_LINK + "/ugpMembros/getMembros");
            const sortedUgp = ugpRequest.data.payload.sort((a, b) => {
                const descrA = a.NOME.toUpperCase(); // Convert to uppercase for case-insensitive sorting
                const descrB = b.NOME.toUpperCase();

                if (descrA < descrB) {
                    return -1;
                }
                if (descrA > descrB) {
                    return 1;
                }
                return 0;
            });
            setUgp(sortedUgp) 

            const firstPost = _.filter(res.data.payload, { descr_en: 'Direction' });
            setPosts(firstPost);

        };

        fetchPosts();
        setLoading(false);
    }, [cargos]);

    useEffect(() => {

        const fetchUi = async () => {
            setLoading(true);
            const res = await axios.get(window.config.DEV_LINK + "/uis/byId?idUi=" + window.config.id);
            setUi(res.data.payload)
        };

        fetchUi();
        setLoading(false);

    }, []);

    useEffect(() => {

        getCargos()
    }, []);


    const getCargos = async () => {
        setLoading(true);
        try {
            const response = await axios.get(window.config.DEV_LINK + '/investigador/getTipo');
          
            const allCargos = response.data.payload;
            const updatedCargos = _.filter(allCargos, cargo => _.includes(["3", "4", "5"], cargo.ID));
            
          
            updatedCargos.push({
                ID: "99", 
                descr_en: "Project Management Unit", 
            });
    
            setCargos(updatedCargos);
            console.log(updatedCargos)
        } catch (error) {
            console.error(error);
        }
        setLoading(false);
    }



    function handlePosts(e) {
        let typeCargo = e.target.value;
        setSelectedCargo(e.target.value)

        setEsac(false);
        setCC(false);
        setDirection(false);
        setIsUgp(false);

        if (typeCargo === "Stakeholders") {
            setCC(true)
        }

        //ESAC
        if (typeCargo === "External Advisory Board") {
            setEsac(true)
        }

        if (typeCargo === "Direction") {
            setDirection(true)
        }

        if (typeCargo === "Project Management Unit") {
            setIsUgp(true)
        }

        else {
            let filtPosts = allPosts.filter((fPosts) => {
                return fPosts.descr_en === typeCargo;
            });
            console.log("filtPosts",filtPosts)
            setPosts(filtPosts);
        }


    }


    console.log(ugp)

    return (
        <div>
            {/* <!-- start team area --> */}
            <section className={`team p-120 ${window.location.pathname === "/team-executive-board" ? "team-page" : 3}`}>
                {loading &&
                    <div className="preloader">
                        <div className="main-circle">
                            <div className="green-circle">
                                <div className="brown-circle">

                                </div>
                            </div>
                        </div>
                    </div>

                }
                {!loading &&
                    <div>
                        <div className="row">
                            <div className="col col-lg-12 col-sm-12" style={{ textAlign: 'center' }}>
                                {cargos.map((cargo, index) => {
                                    const isSelected = cargo.descr_en === selectedCargo;
                                    return (
                                        <Button
                                            className={`mybutton ${isSelected ? 'selected' : ''}`}
                                            size="lg"
                                            style={{ margin: '20px' }}
                                            key={index}
                                            value={cargo.descr_en}
                                            onClick={handlePosts}
                                        >
                                            {cargo.descr_en}
                                        </Button>
                                    )
                                })}
                            </div>
                        </div>

                        <div className="container" style={{ padding: '10px' }}>
                            {!isUgp  ? (
                             isCC ? <Stakeholders /> : (isEsac ? <TeamEsac /> : <Posts posts={posts} loading={loading} ui={ui} direction={isDirection} />))
                            : <PostsUgp posts={ugp}/>
                            }
                        </div>
                    </div>
                }
            </section>
            {/* <!-- end team area --> */}
        </div>
    );
}

export default Governance;
