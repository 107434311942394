import React, { useState, useEffect } from "react";
import axios from "axios";
import _, { } from "lodash";
import Button from 'react-bootstrap/Button'
import { Link } from 'react-router-dom';


const Facilities = () => {
    const [facilities, setFacilities] = useState([]);
    const [loading, setLoading] = useState(true);
    const [cargos, setCargos] = useState([]);
    const [selectedCargo, setSelectedCargo] = useState();
    const [originalFacilities, setOriginalFacilities] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
    
            try {
                const labs = await axios.get(window.config.DEV_LINK + "/laboratorios/getByUi/" + window.config.id);
                setOriginalFacilities(labs.data.payload);
    
                const uniqueSchoolNames = Array.from(new Set(labs.data.payload.map(item => item.nome_escola)));
                setCargos(uniqueSchoolNames);
                
                setSelectedCargo(uniqueSchoolNames[0]);
    
               
                const initialFacilities = labs.data.payload.filter((fPosts) => {
                    return fPosts.nome_escola === uniqueSchoolNames[0];
                });
                setFacilities(initialFacilities);
            } catch (error) {
                console.error(error);
            }
    
            setLoading(false);
        };
    
        fetchData();
    }, []);

    

    function handlePosts(e) {
        let typeCargo = e.target.value;
        setSelectedCargo(typeCargo);

        let selectedFacilities = originalFacilities.filter((fPosts) => {
            return fPosts.nome_escola === typeCargo;
        });

        setFacilities(selectedFacilities);
    }





    return (
        <div>
            {/* <!-- start team area --> */}
            <section className={`team p-120 ${window.location.pathname === "/team-executive-board" ? "team-page" : 3}`}>
                {loading &&
                    <div className="preloader">
                        <div className="main-circle">
                            <div className="green-circle">
                                <div className="brown-circle">

                                </div>
                            </div>
                        </div>
                    </div>

                }
                {!loading &&
                    <div>
                        <div className="row">
                            <div className="col col-lg-12 col-sm-12" style={{ textAlign: 'center' }}>
                                {cargos &&
                                    cargos.map((cargo, index) => {
                                        const isSelected = cargo === selectedCargo;
                                        return (
                                            <Button
                                                className={`mybutton ${isSelected ? 'selected' : ''}`}
                                                size="lg"
                                                style={{ margin: '20px' }}
                                                key={index}
                                                value={cargo}
                                                onClick={handlePosts}
                                            >
                                                {cargo}
                                            </Button>
                                        )
                                    })}
                            </div>
                        </div>

                        <div className="container" style={{ padding: '10px' }}>
                            {facilities.length > 0
                                ? (
                                    <div className="col-lg-12">
                                        <div className="row">
                                            {facilities.map((fac, index) => (

                                                <div className="col-sm-12 col-xl-6 col-lg-6" key={index}>
                                                    <div className="facilities-item">
                                                        <Link
                                                            to={{
                                                                pathname: "/facilities-details/" + fac.id,
                                                                state: {
                                                                    id: fac.id,
                                                                    page_link: fac.id
                                                                },
                                                            }}
                                                        >
                                                            <div className="facilities-image" style={{justifyContent: 'center' }}>
                                                                <img
                                                                    src={fac.imagem_path
                                                                        ? `https://adit.ipvc.pt/modulosipvc_producao/frontend/images/laboratorios/${fac.imagem_path}`
                                                                        : "https://adit.ipvc.pt/modulosipvc_producao/frontend/images/laboratorios/default-facilities.jpg"}
                                                                    alt="Facility"
                                                                />
                                                            </div>
                                                        </Link>
                                                        <div className="eachTeam" style={{ textAlign: "center" }}>
                                                            <span style={{ fontSize: "24px" }}>
                                                                <b>{fac.descricao_lab}</b>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>

                                            ))}
                                        </div>
                                    </div>
                                )
                                : <div></div>
                            }
                        </div>

                    </div>
                }


            </section>
            {/* <!-- end team area --> */}
        </div>
    );
}

export default Facilities;
