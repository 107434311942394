import React from 'react';
import Footer from '../components/global-components/Footer';
import Layouts from '../components/global-components/Layouts';
import HeaderV3 from '../components/section-components/Header-v3';
import ContactInner from '../components/section-components/ContactInner';


const ContactPage = () => {
    
    return (
        <Layouts pageTitle="Contact">
            <HeaderV3 title={"Contacts"} pageName={"Contacts"} />
            <ContactInner />
            <Footer />
        </Layouts>
    );
}

export default ContactPage;
