import React, { useState, useEffect} from "react";
import axios from "axios";

const Policies = () => {

    const [policies, setPolicies] = useState([]);

    useEffect(() => {
        
        const fetchPolicies = async () => {
          const res = await axios.get(window.config.DEV_LINK + "/documentos/policies");
          setPolicies(res.data.payload)
        };
    
        fetchPolicies();
      },[]);


    return (
        <>
            <section className="blog-detail pt-121">
                <div className="container">
                    <div className="row">
                    <div className="col-lg-12" align="justify">
                                    {policies && 
                                        <div className="policies">
                                            {policies.map((pol, index) => (
                                            <div className="alternateColor" key={index}>
                                            <h3>{pol.titulo}</h3>
                                            <hr/>
                                            <div className="policies_box">
                                                <p>The document is available in the following link:<a className="documents"  href={pol.url}
                                                target="_blank"
                                                rel="noopener noreferrer"> Download</a> </p>
                                                
                                            
                                            </div>
                                            </div>
                                            ))}
                                        </div>
                                        }
                                        
                                    </div> 
                    </div>
                </div>
            </section>

        </>
    );
}

export default Policies;