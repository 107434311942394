import React,{useEffect, useState} from 'react';
import Footer from '../components/global-components/Footer';
import Layouts from '../components/global-components/Layouts';
import HeaderV3 from '../components/section-components/Header-v3';
import ProjectV2 from '../components/section-components/Project-v2';
import { baseUrl } from '../data/config.json';
import axios from "axios";


const ProjectPage = () => {
    
    const [banner, setBanner] = useState("");

    try {
        axios.get(baseUrl + "/banner/single.php?pageId=banner_project").then((response) => {
            if (response && response.data && response.data.valor && banner !== response.data.valor) {
                setBanner(response.data.valor)
            }
        });
    } catch (error) {
        console.error(error);
    }

    useEffect(() => {
        window.scrollTo(0,0);
}, [])
    return (
        <>
            <Layouts pageTitle='Projects'>
                <HeaderV3  background={banner} title={"Projects"} pageName={"Projects"}/>
                <ProjectV2 />
                <Footer />
            </Layouts>
        </>
    );
}

export default ProjectPage;
