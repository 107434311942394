import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Home from "../../page/Home";
import ErrorPage from "../../page/Error.jsx";
import PublicationPage from "../../page/Publications";
import TeamPageExecutiveBoard from "../../page/TeamPageExecutiveBoard";
import GovernancePage from "../../page/GovernancePage";
import ProjectPage from "../../page/ProjectPage";
import ServicesPage from "../../page/ServicesPage";
import ServicesDetails from "../../page/ServicesDetails.jsx";
import ContactPage from "../../page/ContactPage";
import ResearchAreasPage from "../../page/ResearchAreasPage";
import ResearchAreasPeoplePage from "../../page/ResearchAreasPeoplePage";
import MissionVisionPage from "../../page/MissionVisionPage";
import DocumentsPage from "../../page/DocumentsPage";
import PoliciesPage from "../../page/PoliciesPage";
import TeamDetails from "../../page/TeamDetails";
import ProjectDetails from "../../page/ProjectDetails";
import FacilitiesPage from "../../page/FacilitiesPage";
import FacilitiesDetails from "../../page/FacilitiesDetails";
import NetworksPage from "../../page/NetworksPage.jsx";
import NewsPage from "../../page/NewsPage.jsx";
import NewsDetails from "../../page/NewsDetails.jsx";
import EventsPage from "../../page/EventsPage.jsx";
import HomeIPVC from "../../page/HomeIPVC.jsx";
import Home2ShowNoImg from "../../page/Home2ShowNoImg.jsx";

const AppRoute = () => {
  const { t } = useTranslation();

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/misvis" component={MissionVisionPage} />
        <Route path="/team" component={TeamPageExecutiveBoard} />
        <Route path="/team-details/:user_id" component={TeamDetails} />
        <Route path="/governance" component={GovernancePage} />
        <Route path="/documents" component={DocumentsPage} />
        <Route path="/policies" component={PoliciesPage} />
        <Route path="/publications" component={PublicationPage} />
        <Route path="/project" component={ProjectPage} />
        <Route path="/project-details/:project_id" component={ProjectDetails}/>
        <Route path="/services" component={ServicesPage} />
        <Route path="/services-details/:project_id" component={ServicesDetails}/>
        <Route path="/contact" component={ContactPage} />
        <Route path="/research-areas" component={ResearchAreasPage} />
        <Route
          path="/resareaPeople/:resID"
          component={ResearchAreasPeoplePage}
        />
        <Route path="/facilities" component={FacilitiesPage} />
        <Route path="/facilities-details/:id" component={FacilitiesDetails} />
        <Route path="/networks" component={NetworksPage} />
        <Route path="/news" component={NewsPage} />
        <Route path="/news-details/:id" component={NewsDetails} />
        <Route path="/events" component={EventsPage} />

        <Route path="/homeNoImg" component={Home2ShowNoImg}/>
        <Route path="/homeIPVC" component={HomeIPVC} />

        <Route component={ErrorPage} />
      </Switch>
    </BrowserRouter>
  );
};

export default AppRoute;
