import React, { useState, useEffect } from "react";
import axios from "axios";
import Posts from "../../data/PostsESAC.js";
import _ from "lodash";


const Stakeholders = () => {


    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage] = useState(1);
    const [postsPerPage, setPostPerPage] = useState();

    useEffect(() => {

        const fetchPosts = async () => {
            setLoading(true);
            const res = await axios.get(window.config.DEV_LINK + "/committe/getByUi/" + window.config.id);
            let data = res.data.payload
            // Remove "\r\n\r\n" characters from "AFILIACAO" property of each object
            let cleanData = data.map(item => {
                item.AFILIACAO = item.AFILIACAO.replace(/\r\n\r\n/g, '');
                return item;
            });
            const esac = _.filter(cleanData, { TIPO_MEMBRO_ID: "5" });
            setPostPerPage(esac.length)

            setPosts(esac);

            setLoading(false)
        };

        fetchPosts();
    }, []);






    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);



    return (
        <div>
            <section className={`team p-120 ${window.location.pathname === "/team-committee" ? "team-page" : 3}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 offset-lg-1">


                        </div>
                    </div>
                    <Posts posts={currentPosts} loading={loading} />
                </div>

            </section>
        </div>
    );
}


export default Stakeholders;
