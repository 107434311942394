import React from 'react';
import Footer from '../components/global-components/Footer';
import Layouts from '../components/global-components/Layouts';
import HeaderV3 from '../components/section-components/Header-v3';
import Publications from '../components/section-components/Publications';


const PublicationPage = () => {

    return (
        <>
            <Layouts pageTitle='Publications'>
                <HeaderV3  title={"Publications"} pageName={"Publications"}/>
                <br></br>
                <Publications/>
                <Footer />
            </Layouts>
        </>
    );
}

export default PublicationPage;