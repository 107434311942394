import React,{useEffect, useState} from 'react';
import Footer from '../components/global-components/Footer';
import Layouts from '../components/global-components/Layouts';
import HeaderV3 from '../components/section-components/Header-v3';
import TeamDetailsInner from '../components/team-components/Team-details';
import { baseUrl } from '../data/config.json';
import axios from "axios";

const TeamDetails = () => {
    
    const [banner, setBanner] = useState("");

    try {
        axios.get(baseUrl + "/banner/single.php?pageId=banner_team").then((response) => {
            if (response && response.data && response.data.valor && banner !== response.data.valor) {
                setBanner(response.data.valor)
            }
        });
    } catch (error) {
        console.error(error);
    }
    
   
    return (
        <>
        
            <Layouts  pageTitle='Team Details'>
                <HeaderV3 background={banner} title={"People"} pageName={"People"} />   
                <TeamDetailsInner/>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <Footer />
            </Layouts>
        </>
    );
}

export default TeamDetails;
