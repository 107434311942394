import React from 'react';
import "animate.css/animate.min.css";

const PageTitle = (props) => {

    return (
        <div className="myBackground-placeholder">
        <div className='container-fluid' style={{padding : '0px'}}>
        
            <div className='row'>
                <div className='col-xl-12'>
                
                    <h1>{props.title}</h1>
                    </div>
                </div>
            </div>
            
            </div>
      

    );
}

export default PageTitle;
