import axios from "axios";
import { useEffect, useState } from "react";



export function usePageData() {

    const [pageData, setPageData] = useState(null);
    
 
    useEffect(() => {
  
      try {
          axios.get(window.config.DEV_LINK + "/uis/byId?idUi=" + window.config.id).then((response) => {
              setPageData(response.data.payload);
              
          });
      } catch (error) {
          console.error(error);
          
      }
    }, []);

    return { pageData };

}