import React from "react";
import { Link } from 'react-router-dom';
let sauce;

export const PostsUgp = ({ posts, loading, ui, direction }) => {
  if (loading) {
    return (
        <ul className="item justify-content-center">
            <h3 style={{ marginTop: '300px', height: "100%" }}>Loading...</h3>
        </ul>);
}
  return (
    <div className="col-lg-12">
      <div className="row justify-content-center">
        {posts.map((post) => {
                    
     
          if (post.IMAGE_PATH === null || post.IMAGE_PATH === '') {
            sauce = "https://adit.ipvc.pt/backend/backend/fotos/adit/test.svg"
          } else {  
            sauce = "https://adit.ipvc.pt/modulosipvc_producao/frontend/images/ugpmembros/" + post.IMAGE_PATH
          }

          
          return (
            <div
              className="col-sm-6 col-xl-3 col-lg-4" key={post.ID}>
              <div className="item" key={post.ID}>
                  
                  <div className="image" style={{display : 'grid', justifyContent: 'center'}}>
                    <img
                      src={sauce}
                      className="img-fluid"
                     
                      alt="Team Member"
                      
                    />

                  </div>
                <div className="eachTeam" style={{ textAlign: "center" }}>
                  <span style={{ fontSize: "24px" }}>
                    <b>{post.NOME} </b>
                  </span>
                  {post.CARGO && //atualizr Project manager fixo
                    <p style={{ fontSize: "18px", color: "Black" }}>
                    <b>Project Manager</b>
                  </p>
                  }
                  <p style={{ fontSize: "18px", color: "Black" }}>
                    {post.EMAIL}
                  </p>

                  
                </div>
              </div>
            </div>

          );
        })}
      </div>
    </div>
  );
};

export default PostsUgp;
