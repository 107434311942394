import React from 'react';
import Footer from '../components/global-components/Footer';
import Layouts from '../components/global-components/Layouts';
import PoliciesInner from '../components/section-components/Policies'
import HeaderV3 from '../components/section-components/Header-v3';


const PoliciesPage = () => {
    
    return (
       <Layouts  pageTitle="Policies">
           <HeaderV3 title={"Policies"} pageName={"Policies"}  />
           <PoliciesInner />
           <div className="section-spacing"></div>
           <Footer />
       </Layouts>
    );
}

export default PoliciesPage;