import React from 'react';
import { CircularProgressbar} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { withRouter } from "react-router-dom";
import axios from "axios";
import "animate.css/animate.min.css";
//import { config } from '../../../public/config';


class Indicator extends React.Component {

    constructor() {
        super();
    
        this.state = {
            indicators: {},
            publications: {}
        };
    }

    componentDidMount = () => {
        
        try {
          axios.get(window.config.DEV_LINK + "/projetos/getById/" + window.config.id).then((response) => {
            this.setState({
                indicators: response.data.payload
            });
          });


        } catch (error) {
          console.error(error);
        }

        try {
            axios.get(window.config.DEV_LINK + "/output/publication/" + window.config.id).then((response) => {
              this.setState({
                  publications: response.data.count
              });
            });
  
  
          } catch (error) {
            console.error(error);
          }
    }



    render() {
        let data = this.state.indicators;
        let pubs = this.state.publications;
        let countProj = data && data[0] && data[0].COUNT_PROJ;
        let countResarchers = data && data[0] && data[0].COUNT_RESEARCHERS;
      
            
        
        

        return ( 
            <div>
                {window.config.INDICATORS === 1 && 
                <section className="count pt-90 bg-blue" style={{justifyContent:'center'}}>
                    <div className="container">
                        <div className="row">
                            {/* Number of Publications */}
                            <div className="col-lg-4 col-md-4 col-xl-4">
                             <div className={`count-item text-center`}>  
                                    <div className="counter">
                                            <CircularProgressbar value={100} text={`${pubs}`}/>
                                            <h5>Publications</h5>
                                </div>
                                </div>
                            </div>
                              
                            
                             {/* Number of R&D Projects & Services */}
                             {data && data[0] &&
                             <div className="col-lg-4 col-md-4 col-xl-4">
                             <div className={`count-item text-center`}>  
                                    <div className="counter">
                                            <CircularProgressbar value={100} text={`${countProj}`}/>
                                            <h5>R&D Projects & Services</h5>
                                </div>
                                </div>
                            </div>
                            }
                            
                            {/* Number of Integrated Researchers */}
                            <div className="col-lg-4 col-md-4 col-xl-4">
                                <div className={`count-item text-center`}>   
                                    <div className="counter">
                                            <CircularProgressbar value={100} text={`${countResarchers}`} />
                                                    <h5>Integrated Researchers</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                  }
            </div>
        
        );
    }
}

export default withRouter(Indicator);