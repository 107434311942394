import React from 'react';
import Footer from '../components/global-components/Footer';
import Layouts from '../components/global-components/Layouts';
import ResearchAreasInner from '../components/section-components/ResearchAreas'
import HeaderV3 from '../components/section-components/Header-v3';


const ResearchAreas = () => {
    
    return (
       <Layouts  pageTitle="Research Areas">
           <HeaderV3 title={"Research Areas"} pageName={"Research Areas"}  />
           <ResearchAreasInner />
           <div className="section-spacing"></div>
           <Footer />
       </Layouts>
    );
}

export default ResearchAreas;