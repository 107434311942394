import React from 'react';
import Footer from '../components/global-components/Footer';
import Layouts from '../components/global-components/Layouts';
import HeaderV3 from '../components/section-components/Header-v3';
import Events from '../components/section-components/Events';


const EventsPage = () => {

    return (
        <>
            <Layouts pageTitle='Events'>
                <HeaderV3  title={"Events"} pageName={"Events"}/>
                <br></br>
                <Events/>
                <Footer />
            </Layouts>
        </>
    );
}

export default EventsPage;