import React, { useState, useEffect } from "react";
import axios from "axios";
import _, { } from "lodash";
import { Link } from 'react-router-dom';



const News = () => {
    const [loading, setLoading] = useState(true);
    const [news, setNews] = useState([])


    useEffect(() => {

        const fetchNews = async () => {
            const res = await axios.get(window.config.DEV_LINK + "/noticias/getById/" + window.config.id);

            const parsedNews = res.data.payload.map(item => ({
                ...item,
                DATA: new Date(item.DATA),
            }));

            const sortedNews = parsedNews.sort((a, b) => b.DATA - a.DATA);
            setNews(sortedNews)
            setLoading(false)
        };

        fetchNews();
    }, []);



    return (
        <div>
            <section className="news">
                {loading &&
                    <div className="preloader">
                        <div className="main-circle">
                            <div className="green-circle">
                                <div className="brown-circle">

                                </div>
                            </div>
                        </div>
                    </div>

                }
                {!loading &&
                    <div>
                        <section className={`service pt-121 pb-90 ${window.location.pathname !== '/about' ? "bg-blue" : " "}`}>
                            {news &&
                                <div className="container">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="row" style={{ placeContent: "center" }}>
                                                {Object.values(news).map((item, i) => {
                                                    // Define the maximum length for the description
                                                    const maxDescriptionLength = 200;

                                                    // Check if the description exceeds the maximum length
                                                    const description =
                                                        item.DESCR.length > maxDescriptionLength
                                                            ? item.DESCR.substring(0, maxDescriptionLength) + "..."
                                                            : item.DESCR;

                                                    return (
                                                        <div className="col-lg-4 col-md-6" key={i} align="justify">
                                                            <Link to={{
                                                                pathname: "/news-details/" + (item.ID),
                                                                state: {
                                                                    id: item.ID,
                                                                },
                                                            }} >
                                                                <div className={`item`}>
                                                                    <div className="news-img-part">
                                                                        {item.IMAGE_PATH ?
                                                                            <img src={"https://adit.ipvc.pt/modulosipvc_producao/frontend/images/noticias/" + item.IMAGE_PATH} className="img-fluid responsive-image" alt="Service Img" />
                                                                            : <img src={"https://adit.ipvc.pt/modulosipvc_producao/frontend/images/noticias/default-news.jpg"} className="img-fluid responsive-image" alt="Service Img" />
                                                                        }
                                                                    </div>
                                                                    <div className="content">
                                                                        <h4>{item.TITULO}</h4>
                                                                        <p>{description}</p>
                                                                        <div className="news-date-outer">
                                                                            <p><i>{item.DATA.toDateString()}</i></p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    );
                                                })}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </section>
                    </div>
                }
            </section>
        </div>
    );
}

export default News;
