import React, { useEffect, useState } from 'react';
import Footer from '../components/global-components/Footer';
import Layouts from '../components/global-components/Layouts';
import HeaderV3 from '../components/section-components/Header-v3';
import { baseUrl } from '../data/config.json';
import axios from 'axios';
import FacilitiesInner from '../components/section-components/Facilities-Inner';

const FacilitiesDetails = () => {
  const [banner, setBanner] = useState('');
  const [facilities, setFacilities] = useState(null);
  const [loading, setLoading] = useState(true);

  let directPageLink = window.location.href.split('/')[4];

  useEffect(() => {
    const fetchData = async () => {
      try {

        const facilitiesResponse = await axios.get(window.config.DEV_LINK + '/laboratorios/getById/' + directPageLink);
        setFacilities(facilitiesResponse.data.payload[0]);
        setLoading(false); 
    } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [directPageLink]);

  return (
    <>
      {loading && (
        <div className="preloader">
            <div className="main-circle">
                <div className="green-circle">
                    <div className="brown-circle">

                    </div>
                </div>
            </div>
        </div>
      )}

      {!loading && facilities && (
        <Layouts pageTitle='Team Details'>
          <HeaderV3 background={banner} title={facilities.descricao_lab} pageName={facilities.descricao_lab} />
          <FacilitiesInner facilities={facilities} />
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <Footer />
        </Layouts>
      )}
    </>
  );
};

export default FacilitiesDetails;
