import React, { useState, useEffect } from "react";
import axios from "axios";
import PostsResArea from "../../data/PostsResArea";


const ResearchAreasPeople = () => {


    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);

    const directPageLink = window.location.href.split('/')[4]
    useEffect(() => {
        try {
            axios.get(window.config.DEV_LINK + "/linhas_tematicas/team?linhas_tematicas_id=" + directPageLink).then((res) => {
                setPosts(res.data.payload)
            }).finally(() => {
                setLoading(false)
            })
        } catch (error) {
            console.log(error)
        }
    },[directPageLink]);


    return (
        <div>
            {/* <!-- start team area --> */}
            <section className={`team p-120 ${window.location.pathname === "/team-executive-board" ? "team-page" : 3}`}>
                {loading &&
                    <div className="preloader">
                        <div className="main-circle">
                            <div className="green-circle">
                                <div className="brown-circle">

                                </div>
                            </div>
                        </div>
                    </div>

                }
                <div>
                    <div className="container" style={{ padding: '10px' }}>
                        {<PostsResArea posts={posts} loading={loading} />}
                    </div>
                </div>

            </section>
            {/* <!-- end team area --> */}
        </div>
    );
}

export default ResearchAreasPeople;
