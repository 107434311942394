import React from "react";

<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>

export const Publication = ({ publication, loading }) => {
    /* let sauce; */

    if (loading) {
        return (
            <ul className="item justify-content-center">
                <h3 style={{ marginTop: '300px', height: "100%" }}>
                    Loading...
                </h3>
            </ul>
        );
    }




    return (
        <div className="publications-show">
            <div className={`col-lg-10 col-md-12`}>
                {publication.map((val) => {
                    return (

                        <div className="card mb-3" key={val.id}>

                            <div className="card-header">
                                <div className="pub-title" dangerouslySetInnerHTML={{ __html: val.artigo }} />
                            </div>
                            {publication &&
                                <div className="row card-body" style={{ padding: "0.2rem" }}>
                                    <h6 className="card-subtitle mb-2 text-muted">{val.info}</h6>
                                    <div className="col-lg-12">
                                        <div className="card-text">
                                            <p className={!val.doi ? 'publications-hide-prop' : undefined} style={{ fontSize: '15px', color: 'GrayText' }}>
                                                <b>Authors:</b> {val.autores}{/* {val.autores_array.map(({
                                                nome
                                            }) => nome).join(', ')
                                            } */}

                                            </p>
                                            <p style={{ fontSize: '15px', color: 'GrayText' }}>
                                                {val.venue ? <><b>Venue: </b>{val.venue}</> : <></>}
                                                {val.jcr !== "" && val.jcr != null && <span> <b>JCR: </b> {val.jcr} </span>}
                                                {val.sjr !== "" && val.sjr != null && <span> <b> SJR: </b> {val.sjr} </span>}
                                            </p>
                                            <div className="publications-bottom">
                                                <b>Year:</b>{val.ano}
                                                <b>Type:</b>{val.tipo_descr_en}
                                                <b> DOI: </b>
                                                <a
                                                    href={
                                                        val.doi != null && !val.doi.includes('http')
                                                            ? 'https://doi.org/' + val.doi
                                                            : val.doi
                                                    }
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    {val.doi}
                                                </a>
                                                <div className="pub-tags-container">
                                                    {val.IS_SCOPUS && (
                                                        <div className="pub-tags scopus">
                                                            <b>Scopus</b>
                                                        </div>
                                                    )}
                                                    {val.IS_WOF && (
                                                        <div className="pub-tags wof">
                                                            Web Of Science
                                                        </div>
                                                    )}
                                                    {val.IS_CROSSREF && (
                                                        <div className="pub-tags crossref">
                                                            CrossRef
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            {val.scimago_quartil === 'Q1' && (
                                                <div className="scimago-container">
                                                    <div className="scimagoQ1-2">
                                                        Scimago Q1
                                                    </div>
                                                </div>
                                            )}
                                            {val.scimago_quartil === 'Q2' && (
                                                <div className="scimago-container">
                                                    <div className="scimagoQ1-2">
                                                        Scimago Q2
                                                    </div>
                                                </div>
                                            )}
                                            {val.scimago_quartil === 'Q3' && (
                                                <div className="scimago-container">
                                                    <div className="scimagoQ3-4">
                                                        Scimago Q3
                                                    </div>
                                                </div>
                                            )}
                                            {val.scimago_quartil === 'Q4' && (
                                                <div className="scimago-container">
                                                    <div className="scimagoQ3-4">
                                                        Scimago Q4
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>


                    );

                })}
            </div>
        </div>
    );

};

export default Publication;
