import React from 'react';
import { withRouter } from "react-router-dom";
import axios from "axios";
//import { config } from '../../data/config';

class ContactInner extends React.Component {
    constructor() {
        super();

        this.state = {
            contacts: [],
            pageData: [],
            labs: [],
            loading: true
        };
    }

    componentDidMount = () => {
        try {
            axios.get(window.config.DEV_LINK + "/configs").then((response) => {
                this.setState({
                    contacts: response.data.payload
                });
            });
        } catch (error) {
            console.error(error);
        }

        try {
            axios.get(window.config.DEV_LINK + "/uis/byId?idUi=" + window.config.id).then((response2) => {
                this.setState({
                    pageData: response2.data.payload
                });
            });
        } catch (error) {
            console.error(error);
        }
        try {
            axios.get(window.config.DEV_LINK + "/contacts/getById/" + window.config.id).then((response4) => {
                this.setState({
                    labs: response4.data.payload,
                    loading: false
                });
            });
        } catch (error) {
            console.error(error);
        }
        
    };

    renderInfoArray = (dataArray) => {
        return (
            <div>
                {dataArray.map((item) => {
                  
                    return (
                        <div>
                            <div className="row">
                                <div key={item.ID} className="contactInner col-lg-9">
                                    <div className="middle mt-md-60">
                                        <h3><b>{item.NOME}</b></h3><br />
                                        <div className="address-container">
                                            <h5>Address:</h5>
                                            <h6> {item.MORADA}</h6>
                                        </div>
                                        <div className="address-container">
                                            <h5>GPS Coords:</h5>
                                            <h6><a href={`http://www.google.com/maps/place/${item.COORDENADAS}`} target="_blank" rel="noopener noreferrer">{item.COORDENADAS}</a></h6>
                                        </div>
                                        <div className="address-container">
                                            <h5>Phone:</h5>
                                            <h6>{item.TELEMOVEL}</h6>
                                        </div>
                                        <div className="address-container">
                                            <h5>E-mail:</h5>
                                            <h6>{item.EMAIL}</h6>
                                        </div>
                                    </div>
                                </div>

                                <div key={item.ID} className="contactInnerMap col-lg-3">

                                    <div style={{ width: "100%" }}>
                                        <iframe
                                            title='unique2'
                                            width="100%"
                                            height="250"
                                            frameBorder="0"
                                            src={`https://maps.google.com/maps?q=${item.COORDENADAS}&z=16&output=embed`}
                                        >
                                        </iframe>
                                    </div>


                                </div>
                            </div>
                            <hr></hr>
                        </div>
                    );
                })}
            </div>
        )
    };

    render() {
        const { contacts, pageData, labs, loading} = this.state;
        return (
            <>
             {loading &&
                    <div className="preloader">
                        <div className="main-circle">
                            <div className="green-circle">
                                <div className="brown-circle">

                                </div>
                            </div>
                        </div>
                    </div>

                }
                <section className="p-120 contact-page">
                    <div className="container contact">
                        <div className="row">
                            <div className="contactInner col-lg-9">
                                {contacts.length > 0 &&
                                    <div className="middle mt-md-60">
                                        <h3><b>IPVC</b> </h3> <br />
                                        <div className="address-container">
                                            <h5>Address:</h5>
                                            <h6>{contacts.find(item => item.id === "4")?.valor}, {contacts.find(item => item.id === "5")?.valor}, {contacts.find(item => item.id === "6")?.valor}</h6>
                                        </div>

                                        <div className="address-container">
                                            <h5>GPS Coords:</h5>
                                            <h6><a href={`http://www.google.com/maps/place/${contacts.find(item => item.id === "7")?.valor}`} target="_blank" rel="noopener noreferrer">{contacts.find(item => item.id === "7")?.valor}</a></h6>
                                        </div>
                                        <div className="address-container">
                                            <h5>{/* <i className="fas fa-phone-alt"></i> */}Phone:</h5>
                                            <h6>{contacts.find(item => item.id === "8")?.valor}</h6>
                                        </div>
                                        <div className="address-container">
                                            <h5> {/* <i className="far fa-envelope"></i> */}E-mail:</h5>
                                            <h6>{pageData.email_unidade}</h6>
                                        </div>

                                    </div>
                                }

                            </div>
                            <div className="contactInnerMap col-lg-3">

                                <div style={{ width: "100%" }}>
                                    <iframe
                                        title='unique'
                                        width="100%"
                                        height="250"
                                        frameBorder="0"
                                        src="https://maps.google.com/maps?width=100%25&amp;height=250&amp;hl=en&amp;q=N%2041%C2%BA%2041'%2036''%20,%20W%208%C2%BA%2050'%2048''+(IPVC)&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                                    >
                                    </iframe>
                                </div>


                            </div>

                        </div>
                        <hr />
                        {this.renderInfoArray(labs)}
                    </div>
                </section>
                <div>
                </div>

            </>
        );
    };
}

export default withRouter(ContactInner);
