import React, { useState, useEffect } from "react";
import axios from "axios";
import SingleProjectInfo from './SingleProject';
import { withRouter } from "react-router-dom";

function ProjectDetailsInner() {

    const [proj, setProj] = useState([])
    const [team, setTeam] = useState([])


    function decodeHtmlEntities(encodedText) {
        const entities = {
            "&lt;": "<",
            "&gt;": ">",
            "&#039;": "'",
            // Add more entities if needed
        };
        return encodedText.replace(/(&lt;|&gt;|&#039;)/g, (match) => entities[match]);
    }


    useEffect(() => {
        let url = window.location.pathname;
        let split = url.split('/');
        let directId = parseInt(split[2]);
        try {
            axios.get(window.config.DEV_LINK + "/projetos/ui/" + window.config.id)
                .then((res) => {
                    for (let index = 0; index < res.data.payload.length; index++) {
                        const element = res.data.payload[index];
                        element.objetivos = decodeHtmlEntities(element.objetivos);
                        element.objetivos = element.objetivos.replace(/(<([^>]+)>)/ig, "")
                        element.objetivos = element.objetivos.replace(".", "")
                        element.atividades = decodeHtmlEntities(element.atividades);
                        element.atividades = element.atividades.replace(/(<([^>]+)>)/ig, "")
                        element.objetivo_principal = decodeHtmlEntities(element.objetivo_principal);
                        element.objetivo_principal = element.objetivo_principal.replace(/(<([^>]+)>)/ig, "")
                    }
                    const semPrestacaoServico = res.data.payload.filter(projeto => projeto.ds_tipo !== "Prestações de Serviço -> Outros");
                    const filteredProj = semPrestacaoServico.find((project) => project.id_projeto === directId);
                    setProj(filteredProj);
                    // Set the cleaned frames in the state
                    return axios.get(window.config.DEV_LINK + "/projetos/" + filteredProj.id_projeto + "/equipa");
                })
                .then((res) => {
                    // Process team details
                    setTeam(res.data.payload);
                })
                .catch((error) => {
                    console.log(error);
                });
        } catch (error) {
            console.log(error);
        }
    }, []);
        
    return (
        <>
            {/* <!-- start team details area --> */}
            <section className="team-detail pt-120 mt-120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <SingleProjectInfo projeto={proj} />
                        </div>
                        {proj &&
                            <div className="col-lg-8">
                                <div className="team-inner right-part" style={{ textAlign: 'justify', textJustify: 'inter-word' }}>
                                    <div className="single-item">
                                        <div className="item-title">
                                            <h4>About</h4>

                                        </div>
                                        <p className="item justify">
                                            {proj.objetivos}
                                        </p>
                                    </div>
                                    <div className="single-item mt-30">
                                        <div className="item-title">
                                            <h4>Team</h4>
                                        </div>
                                        {team.map((item,index) => {
                                            return (
                                            <div key={index} className="project-team-name">
                                                <p>{item.nome} - <b>{item.role}</b></p>
                                            </div>
                                            );
                                            
                                        })}
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </section>
        </>
    );
}

export default withRouter(ProjectDetailsInner);
