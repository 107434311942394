import React from 'react';
import { withRouter } from "react-router-dom";
import axios from "axios";

class MissionVision extends React.Component {

    constructor() {
        super();
        
        this.state = {
            misvis:[]
        };
    }

    componentDidMount = () => {
        try {
            axios.get(window.config.DEV_LINK + "/uis/byId?idUi=" + window.config.id).then((response) => {
                
                this.setState({
                    misvis: response.data.payload,
                });
             });
        
        } catch (error) {
            console.error(error);
        }
    };

    render(){

        let misvis = this.state.misvis;

    return (
        <>
            <section className="p-120">
                <div className="container">
                    <div className="row">
                    
                            <div className="col-lg-12" align="justify">
                                        <div className="section-title">
                                            <h4>MISSION</h4>
                                            <p>{misvis.MISSAO}</p>
                                            <hr/>
                                            <h4>VISION</h4>
                                            <p>{misvis.VISAO}</p>
                                        </div>
                                    </div>
                       
                    </div>
                </div>
            </section>

        </>
    );
}
}

export default withRouter(MissionVision);