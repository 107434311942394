import React from "react";
import axios from "axios";
import SingleMember from './SingleMember';
import { withRouter } from "react-router-dom";
import { fetchPosts } from "../../data/getUser";


class TeamDetailsInner extends React.Component {
    constructor() {
        super();

        this.state = {
            user: [],
            userArticles: [],
            userProjects: [],
            userBolsas: [],
            userThesis: [],
            userPrestServico: [],
            posts: []
        };
    }

    
    componentDidMount = async () => {
        let directPageLink = window.location.href.split('/')[4]

        try {
            if(isNaN(directPageLink)){
            axios.get(window.config.DEV_LINK + "/investigador/getByUsername/" + directPageLink).then((response) => {
                
                this.setState({
                    user: response.data.payload[0],
                    userArticles: response.data.articles,
                    userProjects: response.data.projects,
                    userBolsas: response.data.bolsas,
                    userThesis: response.data.thesis,
                    userPrestServico: response.data.prestServico
                });
            });
            }else{
                axios.get(window.config.DEV_LINK + "/investigador/getById/" + directPageLink).then((response) => {
                    this.setState({
                        user: response.data.payload[0],
                        userArticles: response.data.articles,
                        userProjects: response.data.projects,
                        userBolsas: response.data.bolsas,
                        userThesis: response.data.thesis,
                        userPrestServico: response.data.prestServico
                    });
                });
            }
            if(this.props.location.state){
                this.setState({
                   posts: this.props.location.state.posts
                });
            }else{
                const fetchThePosts = await fetchPosts();
                this.setState({
                    posts: fetchThePosts
                 });
            }
        } catch (error) {
            console.error(error);
        }
        
    };


    render() {
        const user = this.state.user
        const posts = this.state.posts

        return (
            <>
                <section className="team-detail pt-120 ">
                                <SingleMember user={user} id={user.ID} posts={posts} />
                </section>

            </>
        );
    }
}

export default withRouter(TeamDetailsInner);
