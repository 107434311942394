import React from "react";
import { Link } from "react-router-dom";
import SectionTitle from "../global-components/SectionTitle";
import { sectionData } from "./../../data/section.json";
import { withRouter } from "react-router-dom";
import axios from "axios";
import "animate.css/animate.min.css";
//import { config } from '../../../public/config';

class Service extends React.Component {
  constructor() {
    super();

    this.state = {
      researchArea: [],
    };
  }

  componentDidMount = () => {
    try {
      axios
        .get(
          window.config.DEV_LINK +
            "/linhas_tematicas/getById?idUi=" +
            window.config.id
        )
        .then((response) => {
          this.setState({
            researchArea: response.data.payload.combinedResults,
          });
        });
    } catch (error) {
      console.error(error);
    }
  };

  render() {
    let researchArea = this.state.researchArea;
    let data = sectionData.service;

    return (
      <div>
        {/* <!-- start service area --> */}
        <section className={`service pt-121 pb-90`}>
          {researchArea && (
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <SectionTitle title={data.serviceSectionHeading.title} />
                </div>
                <div className="col-12">
                  <div className="row" style={{ placeContent: "center" }}>
                    {Object.values(researchArea).map((item, i) => {
                      // Define the maximum length for the description
                      const maxDescriptionLength = 200;

                      // Check if the description exceeds the maximum length
                      const description =
                        item.DESCR.length > maxDescriptionLength
                          ? item.DESCR.substring(0, maxDescriptionLength) +
                            "..."
                          : item.DESCR;

                      return (
                        <div
                          className="col-lg-4 col-md-6"
                          key={i}
                          align="justify"
                        >
                          <div className={`item`}>
                            <div className="img-part">
                              <img
                                src={
                                  window.config.RESEARCH_AREAS_LINK +
                                  item.IMAGE_PATH
                                }
                                className="img-fluid"
                                alt="Service Img"
                              />
                            </div>
                            <div className="content">
                              <div className="icon">
                                <i className="flaticon-diagram"></i>
                              </div>
                              <h4>{item.DESCR_BREVE}</h4>
                              <p>{description}</p>
                              <Link to="/research-areas">
                                View Details{" "}
                                <i className="fas fa-long-arrow-alt-right"></i>
                              </Link>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          )}
        </section>
        {/* <!-- end service area --> */}
      </div>
    );
  }
}
export default withRouter(Service);
