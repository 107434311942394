import React, { useState, useEffect } from "react";
import axios from "axios";
import _ from "lodash";


const Documents = () => {

  const [documents, setDocuments] = useState([]);
  const [documentsType, setDocumentsType] = useState([]);
  const [language, setLanguage] = useState([])
  const [activitiesReport, setActivitiesReport] = useState([]);
  const [regulations, setRegulations] = useState([]);
  const [financialRegulation, setFinancialRegulation] = useState([])
  const [activitiesPlan, setActivitiesPlan] = useState([]);


  useEffect(() => {

    const fetchDocuments = async () => {
      const res = await axios.get(window.config.DEV_LINK + "/documentos/getById/" + window.config.id);
      const sortedDocuments = res.data.payload.sort((a, b) => b.ANO - a.ANO);
      setDocuments(sortedDocuments);

    };

    const fetchDocumentsType = async () => {
      const res2 = await axios.get(window.config.DEV_LINK + "/documentos/tipo");
      setDocumentsType(res2.data.payload)
    }

    const fetchLanguage = async () => {
      const res3 = await axios.get(window.config.DEV_LINK + "/documentos/idioma");
      setLanguage(res3.data.payload)
    }

    fetchDocuments();
    fetchDocumentsType();
    fetchLanguage();
  }, []);

  useEffect(() => {
    // Filter documents and update activities whenever documents change
    const planoDeAtividades = documents.filter(doc => _.includes("2", doc.TIPO_DOC_ID));
    setActivitiesPlan(planoDeAtividades);
    const relatorioDeAtividades = documents.filter(doc => _.includes("1", doc.TIPO_DOC_ID));
    setActivitiesReport(relatorioDeAtividades)
    const estatutos = documents.filter(doc => _.includes("3", doc.TIPO_DOC_ID));
    setRegulations(estatutos)
    const regulamentoFinanceiro = documents.filter(doc => _.includes("4", doc.TIPO_DOC_ID));
    setFinancialRegulation(regulamentoFinanceiro)

  }, [documents]);


  function getAcronym(id_lang) {
    if (language) {
      const matchingLanguage = language.find(lang => lang.ID_IDIOMA === id_lang);
      if (matchingLanguage) {
        return matchingLanguage.INICIAIS;
      }
    }
    return ""; // Return an empty string if no matching language is found
  }

  return (
    <>
      <section className="blog-detail pt-121">
        <div className="container">
          <div className="row">
            <div className="col-lg-12" align="justify">
              {documentsType &&
                <div className="documents">


                  <h2>{documentsType.find(item => item.ID === "1")?.descricao_en}</h2>
                  <hr />
                  <div className="documents_box">
                    {activitiesReport.map((doc, index) => (
                      <div className="alternateColor" key={index}>
                        <a className="documents" href={`https://adit.ipvc.pt/modulosipvc_producao/frontend/documentos/modulo_documentos/${doc.PATH}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        ><p>{documentsType.find(item => item.ID === "1")?.descricao_en} - {doc.ANO} ({getAcronym(doc.ID_IDIOMA)})</p></a>
                      </div>
                    ))}
                  </div>


                  <h2>{documentsType.find(item => item.ID === "2")?.descricao_en}</h2>
                  <hr />
                  <div className="documents_box">
                    {activitiesPlan.map((doc, index) => (
                      <div className="alternateColor" key={index}>
                        <a className="documents" href={`https://adit.ipvc.pt/modulosipvc_producao/frontend/documentos/modulo_documentos/${doc.PATH}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        ><p>{documentsType.find(item => item.ID === "2")?.descricao_en} - {doc.ANO} ({getAcronym(doc.ID_IDIOMA)})</p></a>
                      </div>
                    ))}
                  </div>


                  <h2>{documentsType.find(item => item.ID === "3")?.descricao_en}</h2>
                  <hr />
                  <div className="documents_box">
                    {regulations.map((doc, index) => (
                      <div className="alternateColor" key={index}>
                        <a className="documents" href={`https://adit.ipvc.pt/modulosipvc_producao/frontend/documentos/modulo_documentos/${doc.PATH}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        ><p>{documentsType.find(item => item.ID === "3")?.descricao_en} - {doc.ANO} ({getAcronym(doc.ID_IDIOMA)})</p></a>
                      </div>
                    ))}
                    </div>
                    <h2>{documentsType.find(item => item.ID === "4")?.descricao_en}</h2>
                  <hr />
                  <div className="documents_box">
                    {financialRegulation.map((doc, index) => (
                      <div className="alternateColor" key={index}>
                        <a className="documents" href={`https://adit.ipvc.pt/modulosipvc_producao/frontend/documentos/modulo_documentos/${doc.PATH}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        ><p>{documentsType.find(item => item.ID === "4")?.descricao_en} - {doc.ANO} ({getAcronym(doc.ID_IDIOMA)})</p></a>
                      </div>
                    ))}
                  </div>

                  {/*   <h2>{documentsType.find(item => item.ID === "4")?.descricao_en}</h2>
                                            <hr/>
                                            <div className="documents_box">
                                            {financialRegulation.map((doc, index) => (
                                                
                                                <div className="alternateColor" key={index}>
                                                <a className="documents"  href={`http://adit.ipvc.pt/modulosipvc_producao/frontend/documentos/modulo_documentos/${doc.PATH}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                ><p>{documentsType.find(item => item.ID === "4")?.descricao_en} - {doc.ANO} ({getAcronym(doc.ID_IDIOMA)})</p></a>
                                                </div>
                                            ))}
                                            </div> */}
                </div>
              }
            </div>
          </div>
        </div>
      </section>

    </>
  );
}

export default Documents;