import React, { useState, useEffect } from "react";
import axios from "axios";
import _, { } from "lodash";
import Button from 'react-bootstrap/Button'
import { Link } from 'react-router-dom';


const Networks = () => {
    const [researchNetworks, setResearchNetworks] = useState([])
    const [loading, setLoading] = useState(true);
    const [cargos, setCargos] = useState([]);
    const [selectedCargo, setSelectedCargo] = useState();
    const [firstNetwork, setFirstNetwork] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);

            try {
                const networks = await axios.get(window.config.DEV_LINK + "/researchnetwork/ui/" + window.config.id);
                const tipos = await axios.get(window.config.DEV_LINK + "/researchnetwork/getTipoResearchNetwork");

                // Sort the networks array alphabetically by the 'descr' field
                const sortedNetworks = networks.data.payload.sort((a, b) => {
                    const descrA = a.descr.toUpperCase(); // Convert to uppercase for case-insensitive sorting
                    const descrB = b.descr.toUpperCase();

                    if (descrA < descrB) {
                        return -1;
                    }
                    if (descrA > descrB) {
                        return 1;
                    }
                    return 0;
                });

                let selectedFirstNetwork = sortedNetworks.filter((fPosts) => {
                    return fPosts.tipo.toUpperCase() === tipos.data.payload[0].descr.toUpperCase();
                });

                const uniqueTipos = new Set(sortedNetworks.map(item => item.tipo));

                setSelectedCargo(tipos.data.payload[0].descr);
                setResearchNetworks(sortedNetworks);
                setFirstNetwork(selectedFirstNetwork);
                setCargos(uniqueTipos);

            } catch (error) {
                console.error(error);
            }

            setLoading(false);
        };

        fetchData();
    }, []);




    function handlePosts(e) {
        let typeCargo = e.target.value;

        setSelectedCargo(e.target.value)
        let selectedFacilitie = researchNetworks.filter((fPosts) => {
            return fPosts.tipo === e.target.value
        });
        setFirstNetwork(selectedFacilitie);



    }


    return (
        <div>
            {/* <!-- start team area --> */}
            <section className={`team p-120 ${window.location.pathname === "/team-executive-board" ? "team-page" : 3}`}>
                {loading &&
                    <div className="preloader">
                        <div className="main-circle">
                            <div className="green-circle">
                                <div className="brown-circle">

                                </div>
                            </div>
                        </div>
                    </div>

                }
                {!loading &&
                    <div>
                        <div className="row">
                            <div className="col col-lg-12 col-sm-12" style={{ textAlign: 'center' }}>
                                {[...cargos].map((cargo, index) => {
                                    const isSelected = cargo === selectedCargo;
                                    return (
                                        <Button
                                            className={`mybutton ${isSelected ? 'selected' : ''}`}
                                            size="lg"
                                            style={{ margin: '20px' }}
                                            key={index}
                                            value={cargo}
                                            onClick={handlePosts}
                                        >
                                            {cargo}
                                        </Button>
                                    );
                                })}
                            </div>
                        </div>


                        <div className="container" style={{ minHeight: '312px', padding: '10px' }}>
                            {firstNetwork.length > 0
                                ? (
                                    <div className="col-lg-12">
                                        <div className="row justify-content-center">
                                            {firstNetwork.map((net) => (
                                                <div className="col-sm-6 col-xl-3 col-lg-4">
                                                    <div className="item-research-network" key={net.id}>
                                                        <div className="research-network-image" style={{ display: 'grid', alignContent: 'center', justifyContent: 'center' }}>
                                                            <a href={net.url} target="_blank" rel="noreferrer">
                                                                <img src={net.image_path && net.image_path !== "data:image/;base64,"
                                                                    ? "https://adit.ipvc.pt/modulosipvc_producao/frontend/images/researchnetwork/" + net.image_path
                                                                    : "https://adit.ipvc.pt/modulosipvc_producao/frontend/images/laboratorios/default-facilities.svg"}
                                                                    className="img-fluid"
                                                                    alt="Team Member" />
                                                            </a>
                                                        </div>
                                                        <div className="eachTeam" style={{ textAlign: "center" }}>
                                                            <span style={{ fontSize: "24px" }}>
                                                                <b>{net.descr} </b>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )
                                : <div></div>
                            }
                        </div>

                    </div>
                }
            </section>
            {/* <!-- end team area --> */}
        </div>
    );
}

export default Networks;
