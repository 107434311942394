import React from 'react';
import NavigationMenu from '../global-components/NavigationMenu';
import PageTitle from './PageTitle';

const HeaderV3 = (props) => {

    return (

        <>
            {/* <!-- start header area --> */}
            <header className={`index2 about-page`}>
               
                            {/* <!-- Start menubar area --> */}
                            <NavigationMenu />
                            {/* <!-- End menubar area --> */}
                              
                             {/* <!-- start banner area --> */}
                            <PageTitle background={props.background ? props.background : 'https://adit.ipvc.pt/backend/backend/fotos/adit//banner/mini.jpg'} title={props.title} pageName={props.pageName} size={props.size ? props.size : '100px'} />
                            {/* <!-- end banner area --> */}
                   </header>  
            
            {/* <!-- end header area --> */}
        </>
    );
}

export default HeaderV3;
