import React from 'react'

export const PaginationPublication = ({ currentPage, publicationPerPage, totalPublication, paginate }) => {
    
    const pageNumbers = [];

   

    for(let i = 1; i <= Math.ceil(totalPublication / publicationPerPage); i++){
        pageNumbers.push(i);
    }

    const pagesToShow = 5;
    let startPage = currentPage - Math.floor(pagesToShow / 2);
    let endPage = currentPage + Math.floor(pagesToShow / 2);

    if (startPage <= 0) {
        endPage -= (startPage - 1);
        startPage = 1;
    }

    if (endPage > pageNumbers.length) {
        endPage = pageNumbers.length;
    }

    if(pageNumbers.length === 0){
        paginate(1);
    }

    return (
        <nav className="nav justify-content-center">
            <ul className="pagination justify-content-center"> 
                {startPage !== 1 && 
                    <>
                        <li key={1} className="page-item" >
                            <a onClick={() => paginate(1) } className="page-link">
                                1
                            </a>
                        </li> 
                        <li className="page-item disabled">
                            <a className="page-link">...</a>
                        </li> 
                    </>
                }
                {pageNumbers.slice(startPage - 1, endPage).map((number) => { 
                    let isCurrentPage = number === currentPage;

                    let pageLinkClass = 'page-link';

                    if(isCurrentPage){
                        pageLinkClass += ' active';
                    }
                    
                    return (
                        <li key={number} className="page-item" >
                             <a onClick={() => {
                                paginate(number);
                                window.scrollTo({
                                    top: 0,
                                    behavior: 'smooth'
                                });
                            }} className={pageLinkClass} >
                                {
                                number
                                }
                            </a>
                        </li>  
                )})}
                {endPage !== pageNumbers.length && 
                    <>
                        <li className="page-item disabled">
                            <a className="page-link">...</a>
                        </li> 
                        <li key={pageNumbers.length} className="page-item" >
                            <a onClick={() => paginate(pageNumbers.length) } className="page-link">
                                {pageNumbers.length}
                            </a>
                        </li> 
                    </>
                }
            </ul>
        </nav>
    )
}

export default PaginationPublication;
