import React from 'react';
import Footer from '../components/global-components/Footer';
import Layouts from '../components/global-components/Layouts';
import DocumentsInner from '../components/section-components/Documents'
import HeaderV3 from '../components/section-components/Header-v3';


const DocumentsPage = () => {
    
    return (
       <Layouts  pageTitle="Documents">
           <HeaderV3 title={"Documents"} pageName={"Documents"}  />
           <DocumentsInner />
           <div className="section-spacing"></div>
           <Footer />
       </Layouts>
    );
}

export default DocumentsPage;