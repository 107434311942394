import React from "react";
import { Link, withRouter } from "react-router-dom";


function SingleProjectInfo(props) {

    const currentDate = new Date();
    let passou = false

    if (props) {
        const dataProj = new Date(props.projeto.data_prevista_fim)
        if (currentDate > dataProj) {
            passou = true;
        }
    }
    return (

        <>
            <div className="team-inner inner-shadow">
                {/* <img src={project.imagem} className="img-fluid" alt="Team" /> */}
                <div className="fig-detail text-center">
                    <h3>{props.projeto.ds_projeto}</h3>
                    {/* {props.projeto.url !== "" && props.projeto.url != null && <p><a href={props.projeto.url} target='_blank' rel="noreferrer">{props.projeto.url}</a></p>} */}

                    <p><b>Type: </b>{props.projeto.ds_regiao}</p>
                    <p>{props.research_area}</p>
                    <p><b>Coordinator: </b> {<Link to={{
                        pathname: "/team-details/" + props.projeto.id_proponente,
                        state: { username: props.projeto.id_proponente }
                    }}>

                         {props.projeto.proponente}</Link>}</p>

                </div>
            </div>
            <div className="team-inner inner-shadow">
                <div className="single-item mt-md-30 personal-info">
                    <div className="item-title">
                        <h4>State</h4>
                    </div>
                    <ul>
                        <li>
                            <p>{passou ? <span> Terminated</span> : <span>In execution</span>}</p>
                        </li>
                    </ul>
                </div>
                <div className="single-item mt-md-30 personal-info">
                    <div className="item-title">
                        <h4>Funded By</h4>
                    </div>
                    <ul>
                        <li>
                            <p><span>{props.projeto.codigo}</span></p>
                        </li>
                    </ul>
                </div>
                <div className="single-item mt-md-30 personal-info">
                    <div className="item-title">
                        <h4>Date</h4>
                    </div>
                    <ul>
                        <li>
                            <p><span><b>Start Date: </b>{props.projeto.data_ini}</span></p>
                        </li>
                        <br></br>
                        <li>
                            <p><span><b>End Date: </b>{props.projeto.data_fim}</span></p>
                        </li>
                    </ul>
                </div>

                <div className="single-item mt-md-30 personal-info">
                    <div className="item-title">
                        <h4>Budget IPVC</h4>
                    </div>
                    <ul>
                        <li>
                            <p><span>{`${parseFloat(props.projeto.valor_apoio).toLocaleString('pt-PT', {
                                style: 'currency',
                                currency: 'EUR',
                            })}`}</span></p>
                        </li>
                    </ul>
                </div>

                <div className="single-item mt-md-30 personal-info">
                    <div className="item-title">
                        <h4>Budget Total</h4>
                    </div>
                    <ul>
                        <li>
                            <p>{`${parseFloat(props.projeto.valor_projeto).toLocaleString('pt-PT', {
                                style: 'currency',
                                currency: 'EUR',
                            })}`}</p>
                        </li>
                    </ul>
                </div>

            </div>

        </>
    );
}


export default withRouter(SingleProjectInfo);
