import React from 'react';

const HeroV2 = ({ pageData, handleImageLoad }) => {
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="main-banner-placeholder">
                    {pageData && (
                        <img
                            src={window.config.HOME_LINK + pageData.banner_path}
                            alt="Banner"
                            onLoad={handleImageLoad}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default HeroV2;
