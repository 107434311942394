import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from 'react-router-dom';

const NewsDetailsInner = () => {

    const [loading, setLoading] = useState(true);
    const [newsInfo, setNewsInfo] = useState([]);

    let directPageLink = window.location.href.split('/')[4]

    useEffect(() => {

        const fetchNews = async () => {
            const res = await axios.get(window.config.DEV_LINK + "/noticias/getById/" + window.config.id);
            const data = res.data.payload
            const singleNews = data.filter(news => news.ID == directPageLink);
            setNewsInfo(singleNews[0])
            setLoading(false)
        };

        fetchNews();
    }, []);


    console.log(newsInfo)

    return (

        <>
            {loading &&
                <div className="preloader">
                    <div className="main-circle">
                        <div className="green-circle">
                            <div className="brown-circle">

                            </div>
                        </div>
                    </div>
                </div>

            }
            {!loading &&
                newsInfo &&
                <section className="p-120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12" >
                             <div className="news-title">
                                {newsInfo.TITULO}
                             </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12" >
                             <div className="news-body">
                                {newsInfo.DESCR}
                             </div>
                             <div className="news-fom">
                             <div className="resitem" style={{ textAlign: 'left' }}>
                                <a href={newsInfo.URL} target="_blank" rel="noopener noreferrer">
                                    Find Out More
                                </a>
                            </div>
                             </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12" >
                             <div className="news-date">
                                <i>{newsInfo.DATA}</i>
                             </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                             <div className="news-image">
                             {newsInfo.IMAGE_PATH ?
                                    <img src={"https://adit.ipvc.pt/modulosipvc_producao/frontend/images/noticias/" + newsInfo.IMAGE_PATH} alt="News Img" />
                                :   <img src={"https://adit.ipvc.pt/modulosipvc_producao/frontend/images/noticias/default.svg"} alt="News Img" />
                            }
                             </div>
                            </div>
                        </div>
                    </div>
                </section>
            }
        </>
    );
}

export default NewsDetailsInner;