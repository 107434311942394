import React from "react";
import { useHistory } from 'react-router-dom';

const FacilitiesInner = (facilities) => {
    const history = useHistory();


    const singleFacility = facilities.facilities;
    const equipamentosArray = singleFacility.equipamentos.split("#");

    if (!facilities) {
        history.push("/facilities");
    }

    return (
        <>
            <section className="p-120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12" align="justify">
                            {singleFacility.descricao_do_lab && 
                            <>
                            <div className="facilities-title">
                                <h3>Description</h3>
                                <p>{singleFacility.descricao_do_lab}</p>
                            </div>
                            <hr></hr>
                            </>
                        }
                            <div className="facilities-title">
                                <h3>Equipment</h3>
                                <ul>
                                    {equipamentosArray.map((equipamento, index) => (
                                        <li key={index}>{equipamento}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default FacilitiesInner;
