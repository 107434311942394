import React, { useState } from "react";
import ScrollTop from "../components/global-components/BackToTop";
import Footer from "../components/global-components/Footer";
import IndicatorsIPVC from "../components/section-components/IndicatorsIPVC";
import Service from "../components/section-components/Service";
import Layouts from "../components/global-components/Layouts";
import MisVis from "../components/section-components/MisVis";
import About from "../components/section-components/About";
import { usePageData } from "../data/usePageData";
import HeaderV3 from "../components/section-components/Header-v3";

const HomeIPVC = () => {
  const { pageData } = usePageData();
  const [isLoading, setIsLoading] = useState(false);

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  return (
    <>
      <div className="home-background-image">
        <div className="home-background-overlay">
          <div className="image-overlay">
            <HeaderV3
              pageData={pageData}
              handleImageLoad={true}
              title={""}
              size="450px"
            />

            {isLoading ? (
              <div className="preloader">
                <div className="main-circle">
                  <div className="green-circle">
                    <div className="brown-circle"></div>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <IndicatorsIPVC />
                <Footer />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeIPVC;
