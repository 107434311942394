import React from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { withRouter } from "react-router-dom";
import axios from "axios";
import "animate.css/animate.min.css";
//import { config } from '../../../public/config';

class IndicatorIPVC extends React.Component {
  constructor() {
    super();

    this.state = {
      indicators: {},
      publications: {},
    };
  }

  componentDidMount = () => {
    try {
      axios
        .get(window.config.DEV_LINK + "/projetos/getById/" + window.config.id)
        .then((response) => {
          this.setState({
            indicators: response.data.payload,
          });
        });
    } catch (error) {
      console.error(error);
    }

    try {
      axios
        .get(window.config.DEV_LINK + "/output/publication/" + window.config.id)
        .then((response) => {
          this.setState({
            publications: response.data.count,
          });
        });
    } catch (error) {
      console.error(error);
    }
  };

  render() {
    let data = this.state.indicators;
    let pubs = this.state.publications;
    let countProj = data && data[0] && data[0].COUNT_PROJ;
    let countResarchers = data && data[0] && data[0].COUNT_RESEARCHERS;

    return (
      <div>
        {window.config.INDICATORS === 1 && (
          <section className="count pt-90" style={{ justifyContent: "center" }}>
            <div className="container">
              <div className="row">
                <div className="col-lg-2 col-md-2 col-xl-2">
                  {/* Number of Publications */}
                  <div className="row">
                    <div className={`count-item text-center`}>
                      <div className="counter">
                        <CircularProgressbar value={100} text={`${pubs}`} />
                        <h5>Publications</h5>
                      </div>
                    </div>
                  </div>

                  {/* Number of R&D Projects & Services */}
                  {data && data[0] && (
                    <div className="row">
                      <div className={`count-item text-center`}>
                        <div className="counter">
                          <CircularProgressbar
                            value={100}
                            text={`${countProj}`}
                          />
                          <h5>R&D Projects & Services</h5>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* Number of Integrated Researchers */}
                  <div className="row">
                    <div className={`count-item text-center`}>
                      <div className="counter">
                        <CircularProgressbar
                          value={100}
                          text={`${countResarchers}`}
                        />
                        <h5>Integrated Researchers</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-10">
                  <section
                    className="about index2"
                    style={{ marginTop: "20px" }}
                  >
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-lg-12" align="justify">
                          <div className="aboutHome">
                            <h2 style={{ color: "white" }}>About</h2>
                            <p style={{ color: "white" }}>
                              The Center for Research and Development in
                              Agrifood Systems and Sustainability (CISAS, FCT
                              Project Reference UID/05937/2020) is a
                              multidisciplinary research and development (R&D)
                              unit, based at the IPVC, and focused on agrifood
                              systems and sustainability issues. CISAS’ mission
                              is to promote sustainable agrifood development in
                              the North of Portugal, contributing to ecological
                              and inclusive transition in agricultural
                              production and community-based systems, creating
                              innovative tools and processes from farm to fork,
                              fostering circular economy. CISAS is organized in
                              2 main areas, Agri-Food Systems, and AgroSystems
                              Resource Management and Sustainability. CISAS
                              integrates the scientific fields of the agrifood
                              chain “from farm to fork”: agronomic sciences,
                              animal and veterinary sciences, soil sciences,
                              food sciences, and consumer sciences.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </section>
        )}
      </div>
    );
  }
}

export default withRouter(IndicatorIPVC);
