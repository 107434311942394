import React from 'react';
import NavigationMenu from '../global-components/NavigationMenu';
//import NavigationMenu from '../global-components/NavigationMenuMine';
import HeroV2 from './Hero-v2';

const HeaderV2 = ({ pageData, handleImageLoad }) => {
    return (
        <>
            <header className="index2">
                <NavigationMenu pageData={pageData} />
            </header>
            <HeroV2 pageData={pageData} handleImageLoad={handleImageLoad} />
        </>
    );
}

export default HeaderV2;
